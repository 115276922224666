





































import { apiAppModule } from '@/api/marketing'
import { Component, Vue } from 'vue-property-decorator'
import config from '@/config'
@Component
export default class MarketingCenter extends Vue {
    menuList: any[] = []

    getModule() {
        apiAppModule().then(res => {
            res.map((item: any) => {
                return item.list.map((el: any) => {
                    el.image = config.baseURL + el.image
                    return el
                })
            })

            res.map((item: any) => {
                let tempMenu: any[] = []
                item.list.map((i: any) => {
                    if (
                        i.name == '分销应用' ||
                        i.name == '用户储值' ||
                        i.name == '商城公告' ||
                        i.name == '商城咨询' ||
                        i.name == '消息通知' ||
                        i.name == '在线客服'
                    ) {
                        tempMenu.push(i)
                    }
                })
                item.list = tempMenu
                // console.log(tempMenu)
                this.menuList.push(item)
            })
            // this.menuList = res
            // console.log('menuList', this.menuList)
        })
    }

    created() {
        this.getModule()
    }
}
